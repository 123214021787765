<template>
  <div class="footer">
    <table>
      <tr>
        <td class="left" colspan="2">
          <div class="ltd_container">
            <img class="footer_logo" :src="footerImg.footerLogo" alt="logo" />
            <div class="detail">
              <strong>卖座，不只是电影</strong>
              <p>
                <span>地址：</span><span>深圳市南山区高新科技园中区科苑路15号<br />科兴科学园B栋3单元1601</span>
              </p>
              <p><span>电话：</span><span>0755-26971933</span></p>
              <p>
                <span>客服：</span><span>400-1808-400</span><br />
                <span :style="{ opacity: 0 }">客服：</span><span :style="{ fontSize: 10 + 'px' }">(周一至周日：09:00-22:30)</span>
              </p>
            </div>
          </div>
        </td>
        <td class="right" colspan="2">
          <div class="rtd_container">
            <img class="footer_qrCode" :src="footerImg.footerQrCode" alt="二维码" />
            <div class="detail">
              <strong>扫码关注「卖座网」公众号</strong>
              <p>Copyright © 2020 maizuo.<br />All Rights Reserved</p>
              <p>深圳市华宇讯科技有限公司 版权所有</p>
              <p>增值业务经营许可证: 粤B2-200502318</p>
            </div>
          </div>
        </td>
      </tr>
    </table>

    <div class="beian">
      备案号
      <a href="http://beian.miit.gov.cn" target="_blank">粤ICP备10001381号</a>
    </div>
    <div id="beian-icon" style="background-color: #fff;"></div>
  </div>
</template>
<script>
import find from 'lodash.find'
export default {
  data() {
    return {
      footerImg: {
        footerLogo: 'https://mall.s.maizuo.com/73552ddcedac25b8a8c8297a565f0d42.jpg',
        footerQrCode: 'https://mall.s.maizuo.com/cfb4d59688fe7865be7ae6fafaa0915e.jpg',
        gongshang: 'https://mall.s.maizuo.com/065580f1e7a40b95957d5ba21fe9a95c.png',
      },
    }
  },
  mounted() {
    const alist = document.querySelectorAll('a')
    const ai = find(alist, v => {
      return v.innerHTML.indexOf('深圳市市场监督管理局企业主体身份公示') >= 0
    })
    if (ai) {
      ai.className = 'gsjg'
      const father = document.getElementById('beian-icon')
      father.appendChild(ai)
    }
    // ai.remove()
  },
}
</script>

<style lang="less">
.footer {
  position: relative;
  width: 100%;
  min-width: 1140px;
  height: 260px;
  background-color: #222426;
  overflow: hidden;
  table {
    width: 1140px;
    margin: 0 auto;
    height: 90%;
    tr {
      td {
        padding: 0;
        margin: 0;
        width: 50%;
        color: #ffffff;
        .ltd_container,
        .rtd_container {
          height: 152px;
          width: 448px;
          margin: 0 auto;
          img {
            display: block;
            float: left;
            margin-right: 30px;
            margin-top: -12px;
          }
          .detail {
            margin-top: -18px;
            float: left;
            display: block;
            text-align: left;
            strong {
              font-size: 18px;
              display: block;
              margin-bottom: 15px;
              font-weight: normal;
            }
            p {
              font-size: 12px;
              line-height: 18px;
              margin: 0;
              overflow: hidden;
              margin-bottom: 8px;
              span {
                display: block;
                float: left;
                // max-width: 306px;
              }
            }
          }
        }
      }
      .left {
        // padding-right: 60px;
        text-align: center;
        .detail {
          width: 348px;
        }
      }
      .right {
        padding-left: 60px;
        text-align: left;
        .detail {
          width: 218px;
        }
      }
    }
  }
  .beian {
    color: #fff;
    width: 100%;
    text-align: center;
    height: 48px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 34px;
    a {
      color: #fff;
    }
  }
  #beian-icon {
    justify-content: center;
    display: flex;
    margin-top: -34px;
    a {
      outline: none;
      img {
        width: 34px;
        height: 48px;
      }
    }
    a:last-child {
      margin-left: 10px;
    }
  }
}
</style>
