import Vue from "vue";
import VueRouter from "vue-router";

const routes = [
  {
    path: "/",
    title: "首页",
    name: "home",
    component: (r) =>
      require.ensure([], () => r(require("./view/home/main.vue")), "home"),
  },
  {
    path: "/download",
    title: "下载App",
    name: "download",
    component: (r) =>
      require.ensure(
        [],
        () => r(require("./view/download/main.vue")),
        "download"
      ),
  },
  {
    path: "/businessCooperation",
    title: "商务合作",
    name: "businessCooperation",
    component: (r) =>
      require.ensure(
        [],
        () => r(require("./view/businessCooperation/main.vue")),
        "businessCooperation"
      ),
  },
  {
    path: "/recruitment",
    title: "诚聘英才",
    name: "recruitment",
    component: (r) =>
      require.ensure(
        [],
        () => r(require("./view/recruitment/main.vue")),
        "recruitment"
      ),
  },
];

Vue.use(VueRouter);

const router = new VueRouter({
  routes,
});

router.afterEach(() => {
  window.scrollTo(0, 0);
});
export default router;
