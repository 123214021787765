<style lang="less">
#main {
  min-width: 1040px;
  max-width: 1920px;
  padding-top: 90px;
  .slide-transition {
    transition: all 0.4s ease;
  }
  .slide-enter {
    transform: translate3d(0, 100%, 0);
    // transform: translate3d(100%, 0, 0);
  }
  .slide-leave {
    transform: translate3d(0, -100%, 0);
    // transform: translate3d(-100%, 0, 0);
  }
  .router-transition-enter-active {
    transition: all 0.3s ease;
  }
  .router-transition-leave-active {
    transition: all 0.5s ease;
  }
  .router-transition-enter,
  .router-transition-leave-active {
    transform: translateY(20px);
    opacity: 0;
  }
}
</style>
<template>
  <div id="main">
    <Nav></Nav>
    <transition name="router-transition" mode="out-in">
      <router-view> </router-view>
    </transition>
    <Footer></Footer>
  </div>
</template>
<script>
import Nav from "./components/nav.vue";
import Footer from "./components/footer.vue";
export default {
  components: { Nav, Footer },
  data() {
    return {
      text: "hello vuex",
    };
  },
};
</script>
