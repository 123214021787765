<template>
  <div class="nav">
    <img :src="logoUrl" />
    <ul class="nav-md">
      <transition name="flag">
        <router-link :to="{path: '/'}"><li :class="{isActive: (isSelect === 'home') }" @click="isSelect = 'home'">首页</li></router-link>
      </transition>
        <router-link :to="{path: '/download'}"><li :class="{isActive: (isSelect === 'download') }" @click="isSelect = 'download'">下载App</li></router-link>
        <router-link :to="{path: '/businessCooperation'}"><li :class="{isActive: (isSelect === 'businessCooperation') }" @click="isSelect = 'businessCooperation'">商务合作</li></router-link>
        <router-link :to="{path: '/recruitment'}"><li class="last" :class="{isActive: (isSelect === 'recruitment') }" @click="isSelect = 'recruitment'">诚聘英才</li></router-link>
        <a href="https://m.maizuo.com/v5/?co=mzmovie"><li class="ticket last"><span>立即购票</span></li></a>
    </ul>
  </div>
</template>
<script>
export default {
  data() {
    return {
      isSelect: "",
      logoUrl: "https://mall.s.maizuo.com/056b03f80ecfb34900d94c1c9a8f5ae6.jpg"
    };
  },
  mounted() {
    // let that = this;
    // setTimeout(function() {
    //   that.getName();
    // }, 100);
  },
  methods: {
    getName: function() {
      let name = this.$route.name;
      this.isSelect = name;
    }
  },
  watch: {
    $route(to, from) {
      this.isSelect = to.name;
    }
  }
};
</script>

<style lang="less">
.nav {
  position: fixed;
  top: 0;
  z-index: 999;
  width: 100%;
  min-width: 1040px;
  height: 90px;
  padding-left: 40px;
  background-color: #fff;
  // overflow: hidden;
  img {
    width: 135px;
    height: 48px;
    margin-top: 22px;
  }
  .nav-md {
    margin: 0;
    height: 90px;
    list-style: none;
    float: right;
    overflow: hidden;
    li {
      position: relative;
      float: left;
      margin-right: 50px;
      font-size: 16px;
      line-height: 90px;
      &:hover {
        animation: suofang 0.5s;
      }
      @keyframes suofang {
        0% {
          transform: scale(1);
        }
        50% {
          transform: scale(1.1);
        }
        100% {
          transform: scale(1);
        }
      }
      &:after {
        content: "";
        position: absolute;
        display: block;
        width: 0;
        height: 2px;
        background-color: #fff;
        bottom: 20px;
        left: 50%;
      }
    }
    .isActive {
      color: #ff5f16;
      transition: all 0.5s;
      &:after {
        transition: all 0.5s;
        content: "";
        position: absolute;
        display: block;
        width: 100%;
        height: 2px;
        background-color: #ff5f16;
        left: 0;
        bottom: 20px;
      }
    }
    .last {
      margin-right: 44px;
    }
    .ticket {
      width: 136px;
      span {
        border-radius: 4px;
        color: #fff;
        background: linear-gradient(180deg, #FF7620 0%, #FF5F16 100%);
        text-align: center;
        display: inline-block;
        width: 100%;
        padding: 0 34px;
        height: 44px;
        line-height: 44px;
        box-sizing: border-box;
      }
    }
  }

}
</style>

